import HttpApi from 'i18next-http-backend';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import keycloak from '../keycloak/keycloak';
import { AccessToken } from '../../models';
import { initReactI18next } from 'react-i18next';
import { missingKeyHandler } from './i18next.utils';


export type Language = 'pl' | 'en'

const UI_PAYMENTS_GATEWAY_INTERFACE_NAMESPACE = "ui-payments-gateway-interface";
export const defaultLanguage: Language = 'pl'

export const initI18n = () => {
  return i18next
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      backend: {
        loadPath: process.env.REACT_APP_API_GATEWAY_URL + "/translations/{{lng}}/{{ns}}",
        allowMultiLoading: false,
        customHeaders: () => {
          return {
            authorization: `Bearer ${keycloak.token}`,
          };
        },
      },
      ns: [UI_PAYMENTS_GATEWAY_INTERFACE_NAMESPACE],
      defaultNS: UI_PAYMENTS_GATEWAY_INTERFACE_NAMESPACE,
      lng: "pl",
      fallbackLng: "pl",
      interpolation: {
        escapeValue: false,
      },
      saveMissing: true,
      saveMissingTo: "all",
      missingKeyHandler,
    });
}

i18next.on('languageChanged', (lng) => {
  // Lang
  document.documentElement.setAttribute('lang', lng);

  // Title
  document.title = lng === 'pl' ? 'Płatności MyBenefit' : 'Payments MyBenefit'

  // Description
  const metas = document.getElementsByTagName("meta") as any;
  metas.description.content = lng === 'pl' ? 'W prosty sposób opłać swoje zamówienia przy pomocy PayU' : 'Easily pay for your orders with PayU'
})

export const getLanguageFromToken = (token: string): Language => {
  const decodedToken = jwtDecode(token) as AccessToken;
  const language: Language | undefined = (decodedToken?.locale || decodedToken?.preferred_language) as Language;
  return language || 'pl'
}

export const i18nextChangeLanguage = async (language: Language) => {
  return i18next.changeLanguage(language);
}